<template>
  <v-container fluid>
    <v-row dense justify="space-between">
      <v-col cols="12">
        <EscolhaOpcaoSelect :inscricao="inscricao" :opcoes="processo.opcoes"/>
        <EscolhaInstrumentoMusicalSelect
            v-if="inscricao.opcao.instrumentoMusicalRequerido"
            :inscricao="inscricao"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from "@/commons/rules";
import EscolhaOpcaoSelect from "@/components/pages/Inscricao/EscolhaOpcaoSelect";
import EscolhaInstrumentoMusicalSelect from "@/components/pages/Inscricao/EscolhaInstrumentoMusicalSelect";

export default {
  name: "InscricaoFormTHEM",

  components: {
    EscolhaOpcaoSelect,
    EscolhaInstrumentoMusicalSelect
  },

  props: {
    processo: {type: Object, required: true},
    inscricao: {type: Object, required: true}
  },

  watch: {
    inscricao: {
      handler() {
        let inscricaoTHEM = {
          inscricao: this.inscricao
        };
        this.$emit("input", inscricaoTHEM);
      },
      deep: true
    },
  },

  methods: {
    ...rules
  }
};
</script>
